:root {
    /* ----------- Border Radius ------------ */
    --menu-bd-r: 4px;
    --toolip-bd-r: 4px;

    --card-bd-r: 8px;
    --img-bd-r: 8px;
    --vid-bd-r: 8px;
    --btn-bd-r: 4px;

    --learn-more-bd: 18px;

    --tag-bd-r: 3px;

    --overview-bd-r-lg: 30px;
    --overview-bd-r-sm: 15px;
    /* ----------- -------- ------------ */
}
