:root {
    /*------------------------ Colors ------------------------*/
    /* ----------- Bg ------------ */
    --bg-primary-gradient: linear-gradient(137deg, rgb(227, 226, 242) 0%, rgb(240, 247, 237) 100%);
    --bg-primary-gradient-revert: linear-gradient(137deg, #f0f7ed 0%, rgb(227, 226, 242) 100%);

    --bg-primary: rgb(247, 250, 245);
    --bg-secondary: rgb(248, 248, 251);
    --bg-tertiary: #f5faf9;

    --bg-secondary-d: var(--secondary-900);
    --bg-tertiary-d: var(--tertiary-800);

    --title-span-primary-bg: linear-gradient(rgba(80, 160, 15, 0.25), rgba(80, 160, 15, 0.25));

    --title-span-secondary-bg: linear-gradient(rgba(76, 57, 154, 0.25), rgba(76, 57, 154, 0.25));

    --title-span-tertiary-bg: linear-gradient(rgba(30, 131, 123, 0.25), rgba(30, 131, 123, 0.25));

    --landing-bg: var(--bg-primary);
    /* ----------- -------- ------------ */

    /* ----------- Layout ------------ */
    --layout-header-bg: var(--bg-primary);
    --layout-header-bd: var(--neutral-300);

    --layout-bottom-bg: var(--bg-primary);
    --layout-bottom-bd: var(--neutral-300);
    --layout-bottom-shadow: 0 -2px 15px 0 rgba(0, 0, 0, 0.08);

    --layout-progress-bar-bg: var(--bg-primary);

    /* ----------- -------- ------------ */

    /* ----------- Progress Bar ------------ */
    --progress-bg: var(--neutral-400);
    --progress-bg-a: var(--tertiary-400);
    --progress-bg-completed: var(--tertiary-500);

    /* ----------- -------- ------------ */

    /* ----------- Card ------------ */
    --card-primary-icon: var(--primary-600);
    --card-secondary-icon: var(--secondary-700);

    --card-bg: #fff;
    --card-bd: var(--neutral-300);
    --card-line: var(--neutral-300);
    --card-shadow: 0 4px 15px 0px rgba(216, 216, 244, 0.35);

    --pricing-card-basic: var(--txt-active);
    --pricing-card-enterprise: var(--txt-active);

    --bar-fill: var(--fill);

    --bar: #c9d9d4;
    --bar-fill-d: #356d69;

    --circle: #d2f5ee;
    --circle-active: #29a397;

    --sidenav-link-bg: #1e837b;
    --sidenav-link-txt: #fff;

    --cube-side-1: #96f050;
    --cube-side-2: #5b41be;
    --cube-side-3: #5b41be;
    --cube-side-4: #41beaf;
    --cube-side-5: #41beaf;
    --cube-side-6: #96f050;
    /* ----------- -------- ------------ */

    /* Link - Learn More */
    --link-learn-more-bg: #fff;
    --link-learn-more-bd: var(--secondary-800);
    --link-learn-more-txt: var(--secondary-800);

    --link-learn-more-bg-h: var(--secondary-100);
    --link-learn-more-bd-h: var(--secondary-800);
    --link-learn-more-txt-h: var(--secondary-800);

    --link-learn-more-bg-f: var(--secondary-50);
    --link-learn-more-bd-f: var(--secondary-800);
    --link-learn-more-txt-f: var(--secondary-800);

    --link-txt: var(--txt-active);
    --link-txt-h: var(--secondary-700);

    /* ----------- Buttons ------------ */
    /* Btn - Reset */
    /* --btn-def-txt: var(--txt-active); */
    /* --lang-btn-txt-h: #6c52d9;
    --lang-btn-txt-h: var(--txt-active);
    --lang-btn-txt-f: #4c399a;
    --lang-btn-txt-f: #6c52d9; */

    /* Btn - Circle More */
    --btn-circle-more: rgba(199, 249, 157, 1);
    --btn-circle-more-h-1: rgba(199, 249, 157, 0.7);
    --btn-circle-more-h-2: rgba(199, 249, 157, 0.4);

    /* Btn - Contact */
    --btn-contact: #acaef5;
    --btn-contact-h: #8e8aef;

    /* Btn - Circle Top */
    --btn-circle-top: rgba(112, 216, 201, 1);
    --btn-circle-top-h-1: rgba(112, 216, 201, 0.7);
    --btn-circle-top-h-2: rgba(112, 216, 201, 0.4);

    /* Btn - Primary */
    --btn-primary-bg: var(--tertiary-600);
    --btn-primary-bd: var(--tertiary-600);
    --btn-primary-txt: var(--tertiary-50);

    --btn-primary-bg-h: var(--tertiary-700);
    --btn-primary-bd-h: var(--tertiary-700);
    --btn-primary-txt-h: var(--tertiary-50);

    --btn-primary-bg-f: var(--tertiary-600);
    --btn-primary-bd-f: var(--tertiary-600);
    --btn-primary-txt-f: var(--tertiary-50);

    /* Btn - Primary-Outline */
    --btn-primary-outline-bg: var(--tertiary-50);
    --btn-primary-outline-bd: var(--tertiary-600);
    --btn-primary-outline-txt: var(--tertiary-800);

    --btn-primary-outline-bg-h: var(--tertiary-100);
    --btn-primary-outline-bd-h: var(--tertiary-600);
    --btn-primary-outline-txt-h: var(--tertiary-600);

    --btn-primary-outline-bg-f: var(--tertiary-50);
    --btn-primary-outline-bd-f: var(--tertiary-600);
    --btn-primary-outline-txt-f: var(--tertiary-600);

    /* Btn - Secondary */
    --btn-secondary-bg: var(--secondary-700);
    --btn-secondary-bd: var(--secondary-700);
    --btn-secondary-txt: var(--secondary-50);

    --btn-secondary-bg-h: var(--secondary-800);
    --btn-secondary-bd-h: var(--secondary-800);
    --btn-secondary-txt-h: var(--secondary-50);

    --btn-secondary-bg-f: var(--secondary-700);
    --btn-secondary-bd-f: var(--secondary-700);
    --btn-secondary-txt-f: var(--secondary-50);

    /* Btn - Secondary - Outline */
    --btn-secondary-outline-bg: var(--secondary-50);
    --btn-secondary-outline-bd: var(--secondary-700);
    --btn-secondary-outline-txt: var(--secondary-700);

    --btn-secondary-outline-bg-h: var(--secondary-100);
    --btn-secondary-outline-bd-h: var(--secondary-700);
    --btn-secondary-outline-txt-h: var(--secondary-700);

    --btn-secondary-outline-bg-f: var(--secondary-50);
    --btn-secondary-outline-bd-f: var(--secondary-700);
    --btn-secondary-outline-txt-f: var(--secondary-700);

    /* Btn - Light-Outline */
    --btn-light-outline-bg: var(--secondary-900);
    --btn-light-outline-bd: var(--secondary-100);
    --btn-light-outline-txt: var(--secondary-50);

    --btn-light-outline-bg-h: var(--secondary-800);
    --btn-light-outline-bd-h: var(--secondary-100);
    --btn-light-outline-txt-h: var(--secondary-50);

    --btn-light-outline-bg-f: var(--secondary-900);
    --btn-light-outline-bd-f: var(--secondary-100);
    --btn-light-outline-txt-f: var(--secondary-50);

    /* Btn - Layout */
    --btn-action-bg: var(--neutral-50);
    --btn-action-bd: var(--neutral-400);
    --btn-action-txt: var(--txt-disabled);

    --btn-action-bg-h: var(--neutral-100);
    --btn-action-bd-h: var(--neutral-400);
    --btn-action-txt-h: var(--txt-inactive);

    --btn-action-bg-f: var(--primary-50);
    --btn-action-bd-f: var(--primary-500);
    --btn-action-txt-f: var(--primary-800);

    /* ----------- -------- ------------ */

    --banner-bg: rgba(80, 160, 15, 0.2);

    --lang-btn-txt: var(--txt-inactive);
    /* --lang-btn-txt-h: #6c52d9; */
    --lang-btn-txt-h: var(--txt-active);
    /* --lang-btn-txt-f: #4c399a; */
    --lang-btn-txt-f: #6c52d9;

    --nav-btn-txt: var(--neutral-500);
    --nav-btn-txt-h: var(--txt-active);
    --nav-btn-txt-f: #6c52d9;

    /* ----------- Tooltip ------------ */
    --tooltip-bg: var(--neutral-800);
    --tooltip-txt: #fff;
    /* ----------- -------- ------------ */

    /* ----------- Text ------------ */

    /* ----------- -------- ------------ */
}
